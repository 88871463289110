import {useState} from 'react';

import defaultTheme from './defaultTheme';
import darkTheme from './darkTheme';
import gxTheme from './gxTheme';
import lightTheme from './lightTheme';
import {createTheme} from '@material-ui/core';

export const createOperaTheme = (isDarkTheme, isGxTheme) => {
  const themes = [defaultTheme];
  if (isDarkTheme) {
    themes.push(darkTheme);
  } else {
    themes.push(lightTheme);
  }
  if (isGxTheme) {
    themes.push(gxTheme);
  }
  return createTheme(...themes);
};

export const setGxTheme = useGxTheme => {
  if (useGxTheme) {
    document.documentElement.classList.add('gx');
  } else {
    document.documentElement.classList.remove('gx');
  }
};

const setColorTheme = isGx => {
  if (!isGx) {
    const hasAccentColor =
      window
        .getComputedStyle(document.body)
        .getPropertyValue('--color-theme-accent') !== '';
    document.documentElement.classList.toggle('color-theme', hasAccentColor);
  }
};

export default () => {
  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
  const [useDarkTheme, setUseDarkTheme] = useState(prefersDarkMode.matches);
  const [useGxTheme, setUseGxTheme] = useState(false);

  const updateDocumentThemeClass = () => {
    if (prefersDarkMode.matches) {
      document.documentElement.classList.remove('theme-light');
      document.documentElement.classList.add('theme-dark');
    } else {
      document.documentElement.classList.remove('theme-dark');
      document.documentElement.classList.add('theme-light');
    }
    setGxTheme(useGxTheme);
    setColorTheme(useGxTheme);
  };
  const updateTheme = () => {
    if (useDarkTheme === prefersDarkMode.matches) {
      return;
    }
    setUseDarkTheme(prefersDarkMode.matches);
    updateDocumentThemeClass();
  };

  if (prefersDarkMode.addEventListener) {
    prefersDarkMode.addEventListener('change', updateTheme);
  } else {
    prefersDarkMode.addListener(updateTheme, 'change');
  }

  updateDocumentThemeClass();

  return [
    createOperaTheme(useDarkTheme, useGxTheme),
    useGxTheme,
    setUseGxTheme,
  ];
};
